<template>
  <el-skeleton animated :loading="isLoaded">
    <template #template>
      <card class="card-stats">
        <div class="row">
          <div class="col">
            <slot>
              <el-skeleton-item variant="h3" />
              <el-skeleton-item variant="p" />
            </slot>
          </div>

          <div class="col-auto">
            <slot name="icon">
              <div class="icon rounded-circle shadow">
                <el-skeleton-item
                  variant="circle"
                  style="width: 100%; height: 100%"
                />
              </div>
            </slot>
          </div>
        </div>

        <p class="mt-3 mb-0 text-sm">
          <slot name="footer"> </slot>
        </p>
      </card>
    </template>
    <template #default>
      <card class="card-stats" :show-footer-line="true">
        <div class="row">
          <div class="col">
            <slot>
              <h5
                class="card-title text-uppercase text-muted mb-0"
                v-html="title"
              />
              <span class="h2 font-weight-bold mb-0" v-html="subTitle" />
            </slot>
          </div>

          <div class="col-auto" v-if="$slots.icon || icon">
            <slot name="icon">
              <div
                class="icon icon-shape text-white rounded-circle shadow"
                :class="[`bg-${type}`, iconClasses]"
              >
                <i :class="icon"></i>
              </div>
            </slot>
          </div>
        </div>

        <p class="mt-3 mb-0 text-sm">
          <slot name="footer"> </slot>
        </p>
      </card>
    </template>
  </el-skeleton>
</template>
<script>
import Card from "./Card.vue";
import { ElSkeleton, ElSkeletonItem } from "element-plus";

export default {
  name: "stats-card",
  components: {
    Card,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    icon: String,
    title: String,
    subTitle: [Number],
    iconClasses: [String, Array],
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoaded() {
      return this.loading;
    },
  },
};
</script>
<style></style>
