const DashboardLayout = () => import("@/views/Layout/DashboardLayout");
// const BlankLayout = () => import("@/views/Layout/BlankLayout");
const DoorprizeList = () => import("@/views/Doorprize/List");
const DoorprizeDraw = () => import("@/views/Doorprize/Draw");

const doorprizes = [
  {
    path: "/doorprizes",
    redirect: "/doorprizes",
    component: DashboardLayout,
    name: "Doorprizes",
    children: [
      {
        path: "",
        name: "Doorprize List",
        components: { default: DoorprizeList },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/doorprizes/draw",
    component: DoorprizeDraw,
    name: "Doorprize Draw",
    meta: { requiresAuth: true, requestPermission: "read-doorprize" },
  },
];

export default doorprizes;
