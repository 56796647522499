<template>
  <div class="form-group">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div
      :class="[
        {
          'has-danger': !!errorMessage,
          'input-group': hasIcon,
          'input-group-alternative': alternative,
          focused: focused,
          'has-label': label || $slots.label,
        },
        inputGroupClasses,
      ]"
    >
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <el-skeleton animated :loading="isLoading">
        <template #default>
          <flat-pickr
            class="form-control"
            :config="flatPickrConfig"
            :name="name"
            :id="name"
            :type="type"
            :placeholder="placeholder"
            :required="required"
            :disabled="disabled"
            :modelValue="inputValue"
            @input="handleChange"
            @blur="focused = false"
            @focus="focused = true"
            :class="inputClasses"
          />
        </template>
        <template #template>
          <el-skeleton-item variant="button" style="width: 100%" />
        </template>
      </el-skeleton>
      <div
        v-if="addonRightIcon || $slots.addonRight"
        class="input-group-append"
      >
        <span class="input-group-text">
          <slot name="addonRight">
            <i :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script>
import { ref } from "vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useField } from "vee-validate";
import { onBeforeMount } from "@vue/runtime-core";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
export default {
  name: "base-flatpickr",
  components: {
    flatPickr,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  props: {
    addonRightIcon: String,
    addonLeftIcon: String,
    append: String,
    flatPickrConfig: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    inputGroupClasses: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined ||
        this.group
      );
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });
    const handleConfig = ref({});
    onBeforeMount(() => {
      handleConfig.value = props.flatPickrConfig;
      handleConfig.value["allowInput"] = false;
      handleConfig.value["disableMobile"] = true;
      handleConfig.value["locale"] = Indonesian;
    });

    return {
      handleConfig,
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style></style>
