<template>
  <Suspense>
    <transition>
      <dashboard-vendor v-if="roleUser == 'vendor'" />
      <dashboard-all v-else />
    </transition>
  </Suspense>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";
import DashboardVendor from "./Components/DashboardVendor.vue";
import DashboardAll from "./Components/DashboardAll.vue";
export default {
  components: {
    DashboardVendor,
    DashboardAll,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const roleUser = computed(() => store.getters["auth/getUserRole"]);
    (() => {
      if (roleUser.value == "operator") {
        router.replace("/scanners/vendor-list");
      }
    })();
    return {
      roleUser,
    };
  },
};
</script>
