import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import _ from "lodash";

import DashboardLayout from "@/views/Layout/DashboardLayout";
// import BlankLayout from "@/views/Layout/BlankLayout";
// import AuthLayout from "@/views/Pages/AuthLayout";

// auth
import AuthPages from "./auth";
import EventPages from "./event";
import GuestPages from "./guest";
import UserPages from "./user";
import ScanPages from "./scanner";
import DoorprizePages from "./doorprize";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Settings from "../views/Settings/Setting.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboard",
    children: [
      {
        path: "/dashboard",
        components: { default: Dashboard },
        meta: { requiresAuth: true },
      },
      {
        path: "/settings",
        name: "Settings",
        components: { default: Settings },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/users" },

  ...AuthPages,
  EventPages,
  ...UserPages,
  ...GuestPages,
  ...DoorprizePages,
  ...ScanPages,
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth) {
    await store.dispatch("auth/initUser");
    let getUser = await store.getters["auth/getUser"];
    if (_.isEmpty(getUser)) {
      return { path: "/auth/login" };
    }
  }
});

export default router;
