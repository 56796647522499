import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/theme-chalk/index.css";
import store from "./store";

import ToastNotification from "./plugins/toast-notification";
import LottieAnimations from "./plugins/lottieAnimations";
import "./assets/css/transition-router.css";
import "./assets/css/font-awesome.css";
import "./assets/css/open-sans-font.css";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(ToastNotification);
appInstance.use(ArgonDashboard);
appInstance.use(LottieAnimations);
appInstance.mount("#app");
