<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-gradient-red navbar-dark': type === 'default' }"
  >
    <div class="d-none d-md-block">
      <route-bread-crumb />
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
    <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4"></nav> -->
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-ungroup"
      >
        <div class="row shortcuts px-4 justify-content-center">
          <router-link
            to="/dashboard"
            class="col-4 shortcut-item"
            v-if="getUserRole != 'operator'"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-blue">
              <i class="ni ni-shop"></i>
            </span>
            <small>Dashboard</small>
          </router-link>
          <router-link
            to="/events"
            class="col-4 shortcut-item"
            v-if="getUserRole == 'super-admin'"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="ni ni-istanbul"></i>
            </span>
            <small>Event</small>
          </router-link>
          <router-link
            to="/guests"
            class="col-4 shortcut-item"
            v-if="getUserRole == 'super-admin' || getUserRole == 'organizer'"
          >
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-purple"
            >
              <i class="fas fa-users"></i>
            </span>
            <small>Guest</small>
          </router-link>
          <router-link
            :to="
              getUserRole != 'operator' ? '/scanners' : '/scanners/vendor-list'
            "
            class="col-4 shortcut-item"
            v-if="getUserRole == 'super-admin' || userPermissions('presences')"
          >
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-warning"
            >
              <i class="fas fa-qrcode"></i>
            </span>
            <small>Scanners</small>
          </router-link>
          <router-link
            to="/doorprizes"
            class="col-4 shortcut-item"
            v-if="getUserRole == 'super-admin' || userPermissions('doorprizes')"
          >
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-green"
            >
              <i class="ni ni-trophy"></i>
            </span>
            <small>Doorprize</small>
          </router-link>
          <router-link
            to="/settings"
            class="col-4 shortcut-item"
            v-if="getUserRole == 'super-admin'"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-info">
              <i class="ni ni-settings"></i>
            </span>
            <small>Settings</small>
          </router-link>
        </div>
      </base-dropdown>
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="/img/theme/avatar-default.jpg"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold" v-html="userName" />
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Welcome!</h6>
        </div>
        <router-link to="/users/profile" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Profile</span>
        </router-link>
        <router-link to="/auth/change-password" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Ubah Password</span>
        </router-link>
        <a href="#!" class="dropdown-item" v-if="getIsAlreadyRequestElevatedRole" @click.prevent="elevateRoleEo">
          <i class="ni ni-single-02"></i>
          <span>Ajukan Menjadi EO</span>
        </a>
        <router-link
          to="/settings"
          class="dropdown-item"
          v-if="getUserRole == 'super-admin'"
        >
          <i class="ni ni-settings-gear-65"></i>
          <span>Settings</span>
        </router-link>
        <!-- <a href="#!" class="dropdown-item">
          <i class="ni ni-calendar-grid-58"></i>
          <span>Activity</span>
        </a>
        <a href="#!" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Support</span>
        </a> -->
        <div class="dropdown-divider"></div>
        <a href="#!" class="dropdown-item" @click.prevent="logout">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import axios from "@/plugins/axios";

export default {
  components: {
    BaseNav,
    RouteBreadCrumb,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapGetters("auth", {
      getUserRole: "getUserRole",
      getUserPermissions: "getUserPermissions",
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    userName() {
      if (this.$store.getters["auth/getUser"]) {
        return this.$store.getters["auth/getUser"].name;
      }
      return "Unauthorized";
    },
    getIsAlreadyRequestElevatedRole() {
      const currentRoleUserName = this.$store.getters["auth/getUserRole"];
      
      this.isRequestElevateRole = currentRoleUserName === 'vendor';

      return currentRoleUserName === 'vendor';
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      toast: useToast(),
      isRequestElevateRole: false
    };
  },
  methods: {
    userPermissions(permission) {
      return this.getUserPermissions.filter(
        (item) => item.name == permission || item.name.includes(permission)
      )?.length;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      await this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.toast.success("Selamat Tinggal");
        })
        .catch((error) => {
          this.toast.error(error);
        })
        .finally(async () => {
          await this.$router.replace({ name: "Login" });
        });
    },
    async elevateRoleEo() {
      await axios.post('users/elevate-role', {roles: 'organizer'})
        .then((res) => {
          this.isRequestElevateRole = true;
          this.toast.success("Berhasil Ajukan Sebagai EO");
        })
    }
  },
  beforeCreate() {
    if (this.$store.getters["auth/getUser"]) {
      this.$store.dispatch("auth/initUser");
    }
  },
};
</script>
