<template>
  <ul
    class="pagination"
    style="overflow-y: scroll"
    :class="
      ([size && `pagination-${size}`, align && `justify-content-${align}`],
      paginationClass)
    "
  >
    <li
      class="page-item"
      :class="{
        'prev-page': item.label.includes('Previous'),
        'next-page': item.label.includes('Next'),
        active: item.active,
        disabled: !item.url,
      }"
      v-for="(item, index) in modelValue"
      :key="index"
    >
      <template v-if="item.label.includes('Previous')">
        <button
          class="page-link prev-page"
          aria-label="Previous"
          @click="changePage(item.url)"
        >
          <i class="fa fa-angle-left"></i>
        </button>
      </template>
      <template
        v-else-if="
          !item.label.includes('Previous') && !item.label.includes('Next')
        "
      >
        <button
          class="page-link"
          @click="changePage(item.url)"
          v-html="item.label"
          :class="{ active: item.active }"
        />
      </template>
      <template v-else>
        <button
          class="page-link next-page"
          aria-label="Next"
          @click="changePage(item.url)"
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </template>
    </li>
  </ul>
</template>
<script>
export default {
  name: "n-pagination",
  emits: ["changePage"],
  props: {
    type: {
      type: String,
      default: "primary",
      validator: (value) => {
        return [
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
        ].includes(value);
      },
    },
    modelValue: {
      type: Object,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    size: {
      type: String,
      default: "",
      description: "Pagination size",
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)",
    },
  },
  computed: {
    paginationLimit() {
      return this.modelValue;
    },
    paginationClass() {
      return `pagination-${this.type}`;
    },
  },
  methods: {
    changePage: function (url) {
      if (url.substr(url.length - 1) != this.currentPage) {
        const emitUrl = url.replace(
          process.env.VUE_APP_BASE_API_URL + "api/",
          ""
        );
        this.$emit("changePage", emitUrl);
      }
    },
  },
};
</script>
<style></style>
