<template>
  <main class="scanner-page shadow-lg bg-gradient-secondary d-flex flex-column">
    <div class="container-fluid bg-dark py-2">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <router-link :to="goBack()">
            <h1 class="text-white">
              <i class="fas fa-chevron-circle-left"></i>
            </h1>
          </router-link>
        </div>
        <div
          class="col text-right"
          v-if="userRole == 'super-admin' || userRole == 'vendor'"
        >
          <base-button
            :type="scanType.value ? 'warning' : 'info'"
            size="sm"
            @click="scanType.value = !scanType.value"
          >
            {{ scanType.value ? "Survey" : "Deal" }}
          </base-button>
        </div>
      </div>
    </div>
    <div class="bg-transparent h-100" style="flex: 1">
      <scanner-qr @onDecode="onDecode" ref="scannerQrEl" />
    </div>
    <div class="scanner-footer" ref="guestInfo" style="bottom: -114px">
      <div
        class="container-fluid rounded-top bg-gradient-secondary shadow py-4"
        style="width: 80%"
      >
        <div class="row align-items-center">
          <div class="col-3">
            <scan-status :valid="guest.valid" />
          </div>
          <div class="col">
            <h3
              v-html="
                guest.valid != 'NOT_FOUND'
                  ? guest.data.name
                  : 'Data Tidak Ditemukan'
              "
            />
            <small
              v-html="
                guest.data.scanned_at ? formatTime(guest.data.scanned_at) : null
              "
            />
          </div>
        </div>
      </div>
    </div>
    <scan-footer />
  </main>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { useToast } from "vue-toastification";
import { useScannerApi } from "./composable/scannerApi";
import ScanFooter from "./components/ScanFooter.vue";
import ScanStatus from "./components/ScanStatus.vue";
import ScannerQr from "./components/ScannerQr.vue";
export default {
  components: {
    ScanFooter,
    ScanStatus,
    ScannerQr,
  },
  setup() {
    const toast = useToast();
    const logo = process.env.BASE_URL + "img/brand/main.png";
    const {
      route,
      router,
      guest,
      scanType,
      userState,
      userRole,
      getPresenceType,
      goBack,
      formatTime,
    } = useScannerApi();
    const guestInfo = ref(null);
    const scannerQrEl = ref(null);

    (() => {
      if (userRole.value != "operator" && userRole.value != "vendor") {
        if (_.isEmpty(route.params.participant_id)) {
          router.replace("/scanners/vendor-list");
        }
      }
    })();

    const getParticipandIdOrUserId = computed(() => {
      if (route.params.participant_id) {
        return route.params.participant_id;
      } else if (!_.isEmpty(userState.value.participants)) {
        return userState.value.participants[0].id;
      }
      return false;
    });

    const onDecode = _.debounce(async (id) => {
      scannerQrEl.value.getCamera = "off";
      if (getParticipandIdOrUserId.value == false) {
        if (userRole.value == "operator") {
          toast.error("Anda belum memilih vendor");
        } else {
          toast.error("Anda belum memiliki akses fitur ini");
        }
        return router.replace(goBack());
      }
      await axios
        .post("/presences/scan/" + id, {
          type: getPresenceType.value,
          participant_id: getParticipandIdOrUserId.value,
        })
        .then((res) => {
          if (!res.data.status) {
            guest.valid = "ALREADY";
          } else {
            guest.valid = "SUCCESS";
          }
          guest.data = res.data.data;
        })
        .catch(() => {
          // if (err.response.status == 404) {
          // console.clear();
          // }
          guest.valid = "NOT_FOUND";
        })
        .finally(() => {
          scannerQrEl.value.getCamera = "auto";
          guest.info = true;
          setTimeout(() => {
            guest.info = false;
          }, 3000);
        });
    }, 300);
    watch(
      () => guest.info,
      (value) => {
        if (value) {
          guestInfo.value.style.bottom = "0";
        } else {
          guestInfo.value.style.bottom = "-114px";
        }
      }
    );
    onMounted(() => {
      document.querySelectorAll("body")[0].style.overflow = "hidden";
    });
    onUnmounted(() => {
      document.querySelectorAll("body")[0].style = "";
    });

    return {
      logo,
      scanType,
      userRole,
      guest,
      goBack,
      guestInfo,
      onDecode,
      formatTime,
      scannerQrEl,
    };
  },
};
</script>
