import axios from "../../plugins/axios";
// const defaultAuthState = () => {
//   return {
//     users: {},
//   };
// };
const state = {
  users: {},
};
const getters = {
  getUser: (state) => {
    return state.users;
  },
  getUserRole: (state) => {
    return state.users.roles[0].name;
  },
  getUserPermissions: (state) => {
    return state.users.roles[0].permissions;
  },
};
const mutations = {
  setUser: (state, payload) => {
    state.users = payload;
  },
};

const actions = {
  login: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post("auth/login", payload)
        .then(async () => {
          await dispatch("initUser");
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.messages);
        });
    });
  },
  initUser: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      if (Object.keys(getters.getUser).length != 0) {
        resolve();
      }
      axios
        .get("auth/me")
        .then((response) => {
          commit("setUser", response.data.data);
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.messages);
        });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      axios
        .post("auth/logout")
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error.response.data.messages);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
