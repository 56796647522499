export default [
  {
    prop: "name",
    label: "Nama",
    minWidth: "300",
    fixed: true,
  },
  {
    prop: "address",
    label: "Alamat",
  },
  {
    prop: "phone",
    label: "No. Ponsel",
  },
  {
    prop: "presence_survey",
    label: "Survey",
    align: "center",
  },
  {
    prop: "presence_deal",
    label: "Deal",
    align: "center",
  },
];
