const DashboardLayout = () => import("@/views/Layout/DashboardLayout");
const GuestList = () => import("@/views/Guests/List");
const GuestCreate = () => import("@/views/Guests/Create");
const GuestEdit = () => import("@/views/Guests/Edit");

const AuthLayout = () => import("@/views/Layout/AuthLayout");
const GuestRegister = () => import("@/views/Guests/Register");
const DownloadQr = () => import("@/views/Guests/DownloadQr");
const PrintQr = () => import("@/views/Guests/PrintQr");

const guests = [
  {
    path: "/guests",
    redirect: "/guests",
    component: DashboardLayout,
    name: "Guests",
    children: [
      {
        path: "",
        name: "Guest List",
        components: { default: GuestList },
        meta: { requiresAuth: true, requestPermission: "read-guest" },
      },
      {
        path: "create",
        name: "Guest Create",
        components: { default: GuestCreate },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
      {
        path: "edit/:id",
        name: "Guest Edit",
        components: { default: GuestEdit },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
    ],
  },
  {
    path: "/guests",
    component: AuthLayout,
    name: "Guest Register",
    children: [
      {
        path: "register",
        name: "Guest Register",
        components: { default: GuestRegister },
      },
      {
        path: "download/:code",
        name: "Download QR",
        components: { default: DownloadQr },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/guests/print/:code",
    name: "Print QR",
    components: { default: PrintQr },
    meta: { requiresAuth: true },
  },
];

export default guests;
