<template>
  <modal
    v-model:show="participantDetail.show"
    bodyClasses="pb-0"
    @close="participantDetail.loading = true"
  >
    <template v-slot:header>
      <el-skeleton animated :loading="participantDetail.loading">
        <template #template>
          <el-skeleton-item variant="p" />
        </template>
        <template #default>
          <h5
            class="modal-title"
            id="exampleModalLabel"
            v-html="participantDetail.name"
          />
        </template>
      </el-skeleton>
    </template>

    <el-table
      v-loading="participantDetail.loading"
      element-loading-background="rgb(255,255,255) !important"
      :data="participantDetail.data.data"
      class="table-responsive table"
      header-row-class-name="thead-light"
      empty-text="Tidak Ada Data"
      table-layout="auto"
      max-height="250"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        label="No"
        width="70"
        fixed
        :index="formatIndex"
      />
      <el-table-column
        prop="guest.name"
        label="Nama"
        width="150"
        fixed
        label-class-name="text-uppercase"
        class-name="text-capitalize"
      />
      <el-table-column
        prop="type"
        label="Tipe"
        label-class-name="text-uppercase"
        class-name="text-capitalize"
      />

      <el-table-column
        prop="scanned_at"
        label="Waktu"
        :formatter="formatTime"
      />
    </el-table>
    <div class="col-12 d-flex justify-content-center flex-wrap mt-4">
      <el-skeleton animated :rows="1" :loading="participantDetail.loading">
        <template #template>
          <el-skeleton-item variant="h1" />
        </template>
        <template #default>
          <base-pagination
            class="pagination-no-border"
            v-model="participantDetail.data.links"
            :currentPage="participantDetail.data.current_page"
            @changePage="handleChangePageModal"
          >
          </base-pagination>
        </template>
      </el-skeleton>
    </div>
    <template v-slot:footer>
      <base-button type="danger" @click="participantDetail.show = false"
        >Tutup</base-button
      >
    </template>
  </modal>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSkeleton,
  ElSkeletonItem,
} from "element-plus";
import Modal from "@/components/Modal.vue";
import { reactive } from "vue";
import axios from "@/plugins/axios";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    Modal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  setup() {
    const participantDetail = reactive({
      name: null,
      show: false,
      loading: true,
      data: {},
    });
    const getParticipantInfo = async (url) => {
      await axios
        .get(url)
        .then((res) => (participantDetail.data = res.data.data))
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          participantDetail.loading = false;
        });
    };
    const handleChangePageModal = _.debounce((url) => {
      participantDetail.loading = true;
      getParticipantInfo(url);
    }, 300);
    const formatIndex = (index) => {
      return index + participantDetail.data.from;
    };
    const formatTime = (row, column, cellValue) => {
      return moment(cellValue).locale("id").format("dddd,DD MMMM YY HH:mm");
    };

    return {
      participantDetail,
      getParticipantInfo,
      formatIndex,
      formatTime,
      handleChangePageModal,
    };
  },
};
</script>
