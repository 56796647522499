<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">List Vendor</h3>
        </div>
        <div class="col text-right">
          <base-button
            size="sm"
            type="danger"
            @click="$emit('changeParticipant', 'top')"
            >Lihat Teratas</base-button
          >
        </div>
      </div>
    </div>
    <div>
      <div class="container-md">
        <el-table
          v-loading="isLoading"
          element-loading-background="rgb(255,255,255) !important"
          class="table-responsive table"
          :data="getParticipants.data"
          header-row-class-name="thead-light"
          header-cell-class-name="px-0 text-center"
          empty-text="Tidak Ada Data"
          table-layout="auto"
        >
          <el-table-column
            label="Vendor"
            sortable
            min-width="350px"
            prop="user.name"
            fixed
            align="center"
            class-name="font-weight-bold"
          />
          <el-table-column
            label="Unique Surveys"
            sortable
            prop="presences_survey_unique_count"
          />
          <el-table-column
            label="Total Surveys"
            sortable
            prop="presences_survey_count"
          />
          <el-table-column
            label="Total Deals"
            sortable
            prop="presences_deal_count"
          />
          <el-table-column label="#" align="center" width="50">
            <template #default="scope">
              <div class="d-flex flex-no-wrap justify-content-center">
                <base-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleParticipantInfo(scope.$index, scope.row)"
                >
                  <i class="fas fa-info-circle"></i>
                </base-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="card-footer">
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-skeleton animated :rows="1" :loading="isLoading">
          <template #template>
            <el-skeleton-item variant="h1" />
          </template>
          <template #default>
            <div>
              <p class="card-category">
                Urutan
                {{ getParticipants.from + "-" + getParticipants.to }} dari Total
                {{ getParticipants.total }} data
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="getParticipants.links"
              @changePage="handleChangePage"
              :currentPage="getParticipants.current_page"
            >
            </base-pagination>
          </template>
        </el-skeleton>
      </div>
    </div>
    <participant-details-modal ref="participantDetailsModal" />
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSkeleton,
  ElSkeletonItem,
} from "element-plus";
import ParticipantDetailsModal from "./ParticipantDetailsModal.vue";
import { computed, ref } from "vue";
import _ from "lodash";
import axios from "@/plugins/axios";
import { useToast } from "vue-toastification";
export default {
  name: "participant-details-all",
  props: {
    participants: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ParticipantDetailsModal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  setup(props, { emit }) {
    const participantProps = computed(() => props.participants);
    const isLoading = ref(true);
    const toast = useToast();
    const getParticipants = ref({});
    const fetchParticipants = _.debounce(async (url = null) => {
      await axios
        .get(url ?? "participants/dashboard")
        .then((res) => {
          getParticipants.value = res.data.data;
        })
        .catch((err) => {
          emit("changeParticipant", "top");
          toast.error(err.response.data.message);
        })
        .finally(() => (isLoading.value = false));
    });
    fetchParticipants();

    const participantDetailsModal = ref(null);
    const handleParticipantInfo = _.debounce((index, row) => {
      console.log(row);
      const refEl = participantDetailsModal.value.participantDetail;
      refEl["name"] = row.user.name;
      refEl["show"] = refEl["loading"] = true;
      participantDetailsModal.value.getParticipantInfo(
        "presences/guest-list/" + row.id
      );
    }, 300);

    const handleChangePage = (url) => {
      isLoading.value = true;
      fetchParticipants(url);
    };

    return {
      participantProps,
      isLoading,
      getParticipants,
      handleParticipantInfo,
      handleChangePage,
      fetchParticipants,
      participantDetailsModal,
    };
  },
};
</script>
<style></style>
