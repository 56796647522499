<template>
  <div class="wrapper">
    <side-bar :logo="logo">
      <template #links>
        <sidebar-item
          key="dashboard"
          v-if="getUserRole != 'operator'"
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard',
          }"
        />

        <sidebar-item
          v-if="getUserRole == 'super-admin'"
          key="event"
          :link="{
            name: 'Event',
            icon: 'ni ni-istanbul text-red',
            path: '/events',
          }"
        />
        <sidebar-item
          key="user"
          :link="{
            name: 'User',
            icon: 'fas fa-users',
          }"
        >
          <sidebar-item
            v-if="getUserRole == 'super-admin' || getUserRole == 'organizer'"
            key="user-list"
            :link="{
              name: 'User List',
              icon: 'fas fa-users',
              path: '/users/list',
            }"
          />
          <sidebar-item
            v-if="getUserRole == 'super-admin'"
            key="user-list"
            :link="{
              name: 'User Elevate Role List',
              icon: 'fas fa-users',
              path: '/users/elevate-role-list',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="getUserRole == 'super-admin' || getUserRole == 'organizer'"
          key="guest"
          :link="{
            name: 'Guests',
            icon: 'fas fa-users text-primary',
            path: '/guests',
          }"
        />

        <sidebar-item
          v-if="getUserRole == 'super-admin' || userPermissions('presences')"
          key="scanners"
          :link="{
            name: 'Scanners',
            icon: 'fas fa-qrcode text-warning',
            path:
              getUserRole != 'operator' ? '/scanners' : '/scanners/vendor-list',
          }"
        />

        <sidebar-item
          key="doorprizes"
          :link="{
            name: 'Doorprizes',
            icon: 'ni ni-trophy text-info',
          }"
        >
          <sidebar-item
            v-if="getUserRole == 'super-admin'"
            key="doorprizes-draw"
            :link="{
              name: 'Draw',
              icon: 'ni ni-trophy text-info',
              path: '/doorprizes/draw',
            }"
          />
          <sidebar-item
            v-if="getUserRole == 'super-admin' || userPermissions('doorprizes')"
            key="doorprizes-list"
            :link="{
              name: 'List',
              icon: 'ni ni-trophy text-info',
              path: '/doorprizes',
            }"
          />
        </sidebar-item>
      </template>

      <template v-slot:links-after>
        <hr class="my-3" />
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item" v-if="getUserRole == 'super-admin'">
            <router-link to="/settings" class="nav-link">
              <i class="ni ni-settings"></i>
              <span class="nav-link-text">Settings</span>
            </router-link>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" />

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ContentFooter from "@/views/Layout/ContentFooter";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ContentFooter,
    DashboardNavbar,
  },
  data() {
    return {
      logo: process.env.BASE_URL + "img/brand/main.png",
    };
  },
  computed: {
    ...mapGetters("auth", {
      getUserRole: "getUserRole",
      getUserPermissions: "getUserPermissions",
    }),
  },
  methods: {
    userPermissions(permission) {
      return this.getUserPermissions.filter(
        (item) => item.name == permission || item.name.includes(permission)
      )?.length;
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
