import _ from "lodash";
import axios from "@/plugins/axios";
import moment from "moment";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export function useExportGuest() {
  const exportGuestBtn = ref(true);
  const toast = useToast();
  const exportGuest = _.debounce(async () => {
    exportGuestBtn.value = true;
    await axios
      .get("guests/export", {
        responseType: "blob",
      })
      .then((res) => {
        if (res.status == 204) {
          return toast.error("Data kosong");
        }
        const timestamps = moment().locale("id").format("Do-MMM-YY-hmm");
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report-${timestamps}.xlsx`);
        link.click();
      })
      .finally(() => {
        exportGuestBtn.value = false;
      });
  }, 300);

  return {
    exportGuestBtn,
    exportGuest,
  };
}
