export default [
  {
    title: "Total Vendors",
    type: "gradient-red",
    icon: "ni ni-active-40",
    value: "participants_count",
  },
  {
    title: "Total Guests",
    type: "gradient-red",
    icon: "ni ni-active-40",
    value: "guests_count",
  },
  {
    title: "Total Surveys",
    type: "gradient-orange",
    icon: "ni ni-chart-pie-35",
    value: "presence_survey",
  },
  {
    title: "Total Deals",
    type: "gradient-green",
    icon: "ni ni-money-coins",
    value: "presence_deal",
  },
];
