<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Top 5 Vendor</h3>
        </div>

        <div class="col text-right">
          <base-button
            size="sm"
            type="danger"
            :disabled="getParticipant()"
            @click="$emit('changeParticipant', 'all')"
            >Lihat Semua</base-button
          >
          <!-- :disabled="participantProps?.length != 0" -->
        </div>
      </div>
    </div>
    <div>
      <div class="container-md">
        <el-table
          class="table-responsive table"
          :data="participantProps"
          header-row-class-name="thead-light"
          header-cell-class-name="px-0 text-center"
          empty-text="Tidak Ada Data"
          table-layout="auto"
        >
          <el-table-column
            label="Vendor"
            min-width="350px"
            prop="user.name"
            fixed
            align="center"
            class-name="font-weight-bold"
          />
          <el-table-column
            label="Unique Surveys"
            prop="presence_survey_unique"
          />
          <el-table-column label="Total Surveys" prop="presence_survey" />
          <el-table-column label="Total Deals" prop="presence_deal" />
          <!-- <el-table-column label="Total Deals" prop="presence_deal_unique" /> -->
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { ElTable, ElTableColumn } from "element-plus";
import { computed } from "vue";
import _ from "lodash";
export default {
  name: "page-visits-table",
  props: {
    participants: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  setup(props) {
    const participantProps = computed(() => props.participants);
    const getParticipant = () => {
      _.isEmpty(participantProps);
    };

    return {
      participantProps,
      getParticipant,
    };
  },
};
</script>
<style></style>
