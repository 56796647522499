import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const ToastOptions = {
  transition: "Vue-Toastification__bounce",
  type: "info",
  maxToasts: 5,
  newestOnTop: true,
  position: "bottom-right",
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

export default {
  install(app) {
    app.use(Toast, ToastOptions);
  },
};
