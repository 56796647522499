<template>
  <lottie-animation
    v-if="isValid == 'NOT_FOUND'"
    :loop="true"
    :autoPlay="true"
    :animationData="require('@/assets/lottie/notfound.json')"
  />
  <lottie-animation
    v-else-if="isValid == 'ALREADY'"
    :loop="true"
    :autoPlay="true"
    :animationData="require('@/assets/lottie/failed.json')"
  />
  <lottie-animation
    v-else
    :loop="true"
    :autoPlay="true"
    :animationData="require('@/assets/lottie/success.json')"
  />
</template>
<script>
import { computed } from "@vue/runtime-core";
export default {
  props: {
    valid: {
      type: String,
      default: "NOT_FOUND",
    },
  },
  setup(props) {
    return {
      isValid: computed(() => props.valid),
    };
  },
};
</script>
