const DashboardLayout = () => import("@/views/Layout/DashboardLayout");
// const GuestList = () => import("@/views/Guests/List");
// const GuestCreate = () => import("@/views/Guests/Create");
const AuthLayout = () => import("@/views/Layout/AuthLayout");
const UserRegister = () => import("@/views/Users/Register");
const UserProfile = () => import("@/views/Users/Profile");
const UserList = () => import("@/views/Users/List");
// const UserResetPassword = () => import("@/views/Users/ResetPassword");
const UserCreate = () => import("@/views/Users/Create");
const UserElevateRoleList = () => import("@/views/Users/UserElevateRoleList");

const users = [
  {
    path: "/users/register",
    component: AuthLayout,
    name: "User Register",
    children: [
      {
        path: "",
        name: "User Register",
        components: { default: UserRegister },
      },
    ],
  },
  {
    path: "/users",
    redirect: "/users/profile",
    component: DashboardLayout,
    children: [
      {
        path: "profile",
        name: "User Profile",
        components: { default: UserProfile },
        meta: { requiresAuth: true },
      },
      {
        path: "list",
        name: "User List",
        components: { default: UserList },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
      {
        path: "elevate-role-list",
        name: "User Elevate Role List",
        components: { default: UserElevateRoleList },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
      // {
      //   path: "reset-password/:id",
      //   name: "User Reset Password",
      //   components: { default: UserResetPassword },
      //   meta: { requiresAuth: true, requiresRole: "super-admin" },
      // },
      {
        path: "create",
        name: "User Create",
        components: { default: UserCreate },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
      {
        path: "assign",
        name: "User Assign",
        components: { default: UserProfile },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
    ],
  },
];

export default users;
