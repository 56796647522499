<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">List Guest</h3>
        </div>
      </div>
    </div>
    <div>
      <div class="container-md">
        <el-table
          v-loading="isLoading"
          element-loading-background="rgb(255,255,255) !important"
          empty-text="Tidak Ada Data"
          table-layout="auto"
          :data="guestData.data"
        >
          <el-table-column
            type="index"
            label="#"
            fixed
            align="center"
            :index="formatBaseTableIndex"
          />
          <el-table-column
            v-for="item in BaseVendorTable"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :align="item.align ?? null"
            :fixed="item.fixed ?? false"
            :min-width="item.minWidth ?? null"
          />
          <el-table-column align="center" width="50">
            <template #default="scope">
              <div class="d-flex flex-no-wrap justify-content-center">
                <base-button
                  type="info"
                  size="sm"
                  icon
                  @click="guestInfoHandler(scope.$index, scope.row)"
                >
                  <i class="fas fa-info-circle"></i>
                </base-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="card-footer">
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-skeleton animated :rows="1" :loading="isLoading">
          <template #template>
            <el-skeleton-item variant="h1" />
          </template>
          <template #default>
            <div>
              <p class="card-category">
                Urutan
                {{ guestData.from + "-" + guestData.to }} dari Total
                {{ guestData.total }} data
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="guestData.links"
              :currentPage="guestData.current_page"
              @changePage="handleChangePage"
            >
            </base-pagination>
          </template>
        </el-skeleton>
      </div>
    </div>
    <modal v-model:show="guestInfoModal.show" bodyClasses="pb-0">
      <template v-slot:header>
        <el-skeleton animated :loading="guestInfoModal.loading">
          <template #template>
            <el-skeleton-item variant="p" />
          </template>
          <template #default>
            <h5
              class="modal-title"
              id="exampleModalLabel"
              v-html="guestInfoModal.name"
            />
          </template>
        </el-skeleton>
      </template>

      <el-table
        v-loading="guestInfoModal.loading"
        element-loading-background="rgb(255,255,255) !important"
        :data="guestInfoModal.data.data"
        class="table-responsive table"
        header-row-class-name="thead-light"
        empty-text="Tidak Ada Data"
        table-layout="auto"
        max-height="200"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="No"
          width="70"
          fixed
          :index="formatIndex"
        />
        <el-table-column
          prop="type"
          label="Tipe"
          width="100"
          fixed
          label-class-name="text-uppercase"
          class-name="text-capitalize"
        />
        <el-table-column
          prop="scanned_at"
          label="Waktu"
          :formatter="formatTime"
        />
      </el-table>
      <div class="col-12 d-flex justify-content-center flex-wrap mt-4">
        <el-skeleton animated :rows="1" :loading="guestInfoModal.loading">
          <template #template>
            <el-skeleton-item variant="h1" />
          </template>
          <template #default>
            <base-pagination
              class="pagination-no-border"
              v-model="guestInfoModal.data.links"
              :currentPage="guestInfoModal.data.current_page"
              @changePage="handleChangePageModal"
            >
            </base-pagination>
          </template>
        </el-skeleton>
      </div>
      <template v-slot:footer>
        <base-button type="danger" @click="guestInfoModal.show = false"
          >Tutup</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSkeleton,
  ElSkeletonItem,
} from "element-plus";
import Modal from "@/components/Modal.vue";
import { computed, reactive } from "vue";
import BaseVendorTable from "../helpers/baseVendorTable";
import axios from "@/plugins/axios";
import _ from "lodash";
import moment from "moment";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
    Modal,
  },
  setup(props, { emit }) {
    const guestData = computed(() => props.data);
    const guestInfoModal = reactive({
      name: null,
      data: {},
      show: false,
      loading: true,
      guest_id: null,
    });
    const formatBaseTableIndex = (index) => {
      return index + guestData.value.from;
    };
    const formatIndex = (index) => {
      return index + guestInfoModal.data.from;
    };
    const formatTime = (row, column, cellValue) => {
      return moment(cellValue).locale("id").format("dddd,DD MMMM YY HH:mm");
    };
    const getGuestInfo = async (guest_id, url = null) => {
      guestInfoModal.loading = true;
      await axios
        .get(url ?? "presences/list/" + guest_id)
        .then((res) => {
          guestInfoModal.data = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          guestInfoModal.loading = false;
        });
    };
    const guestInfoHandler = _.debounce(async (index, row) => {
      guestInfoModal.name = row.name;
      guestInfoModal.guest_id = row.id;
      await getGuestInfo(row.id);
      guestInfoModal.show = true;
    }, 300);
    const handleChangePage = _.debounce((url) => {
      emit("changePage", url);
    });
    const handleChangePageModal = _.debounce((url) => {
      getGuestInfo(guestInfoModal.guest_id, url);
    }, 300);
    return {
      BaseVendorTable,
      isLoading: computed(() => props.loading),
      guestData: computed(() => props.data),
      guestInfoHandler,
      guestInfoModal,
      formatBaseTableIndex,
      formatIndex,
      formatTime,
      handleChangePage,
      handleChangePageModal,
    };
  },
};
</script>
<style></style>
