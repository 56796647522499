const AuthLayout = () => import("@/views/Layout/AuthLayout");
import DashboardLayout from "@/views/Layout/DashboardLayout";
const Login = () => import("@/views/Auth/Login.vue");
const Register = () => import("@/views/Auth/Register.vue");
const Transition = () => import("@/views/Auth/Transition.vue");
const ChangePassword = () => import("@/views/Auth/ChangePassword.vue");
const UserRegisterBySlug = () => import("@/views/Users/RegisterBySlug");

export default [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: AuthLayout,
    name: "Auth",
    children: [
      {
        path: "login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "register",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "register/:event_slug",
        name: "Vendor Register Slug",
        components: { default: UserRegisterBySlug },
        meta: { requiresAuth: false, requiresRole: null },
      }
    ],
  },
  {
    path: "/auth/transition",
    component: Transition,
    name: "Auth Transition",
  },
  {
    path: "/auth/change-password",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "Auth Change Password",
        components: { default: ChangePassword },
        meta: { requiresAuth: true, requiresRole: "super-admin" },
      },
    ],
  },
];
