<template>
  <div class="fixed-bottom" style="z-index: 1">
    <div class="container-fluid mb-2">
      <div class="row text-center">
        <div class="col">
          <div>
            <small class="font-weight-bold text-white-50">Powered By</small>
          </div>
          <img :src="logo" style="width: 60px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {
      logo: process.env.BASE_URL + "img/brand/main.png",
    };
  },
};
</script>
