import axios from "axios";
import router from "../router";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL + "api/";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    // 2xx code
    return response;
  },
  (error) => {
    // outside 2xx
    //handle expired token
    if (
      (error.response.status == 401 &&
        error.response.data.messages.includes("Authorization token")) ||
      (error.response.status == 403 &&
        error.response.data.messages.includes("Invalid Token or Expired Token"))
    ) {
      router.replace({ path: "/auth" });
      return useToast().error(error.response.data.messages);
    }
    if (
      error.response.status == 403 &&
      error.response.data.messages ==
        "User does not have any of the necessary access rights."
    ) {
      if (useStore().getters["auth/getUserRole"] == "operator") {
        router.push("/scanners/vendor-list");
      } else {
        router.go(-1);
      }
      return useToast().error(error.response.data.messages);
    }
    return Promise.reject(error);
  }
);

export default axios;
