import ScannerLayout from "@/views/Layout/ScannerLayout";

import ScanPages from "../views/Scanners/Scan.vue";
import VendorList from "../views/Scanners/VendorList.vue";

// beforeEnter: (to, from) => {
// store.getters["auth/userPermissionCheck"];
// if (
//   store.getters["auth/getUser"].roles[0].name != to.meta.requiresRole
// ) {
//   console.log("if");
//   return from.fullPath;
// }
// },
export default [
  {
    path: "/scanners",
    component: ScannerLayout,
    children: [
      {
        path: ":participant_id?",
        name: "Scanners",
        components: { default: ScanPages },
        meta: {
          requiresAuth: true,
          requestPermission: ["survey-presences", "deal-presences"],
        },
      },
      {
        path: "vendor-list",
        name: "Scanner Vendor List",
        components: { default: VendorList },
        meta: {
          requiresAuth: true,
          requestPermission: ["survey-presences", "deal-presences"],
        },
      },
    ],
  },
];
