<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Aktifasi Event</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-md-between flex-md-row flex-column flex-wrap"
            >
              <div class="row">
                <div class="col" v-cloak>
                  <transition>
                    <h4>
                      <span v-if="activeEvent && !isLoading"
                        >Event Aktif :
                        <span class="text-warning" v-html="activeEvent.name" />
                      </span>
                    </h4>
                  </transition>
                </div>
              </div>
              <div class="align-self-sm-center">
                <el-skeleton animated :loading="isLoading">
                  <template #template>
                    <el-skeleton-item variant="button" />
                  </template>
                  <template #default>
                    <el-input
                      class="mb-3"
                      clearable
                      :prefix-icon="Search"
                      placeholder="Cari Event"
                      v-model="searchQuery"
                      :disabled="!(activeEvent ?? true)"
                    />
                  </template>
                </el-skeleton>
              </div>
            </div>
            <div class="container-md">
              <el-table
                v-loading="isLoading"
                element-loading-background="rgb(255,255,255) !important"
                :data="tableData"
                row-key="id"
                header-row-class-name="thead-light"
                table-layout="auto"
                :border="true"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  v-bind="column"
                  :formatter="column.prop == 'date' ? formatDate : null"
                  :index="column.type == 'index' ? formatIndex : null"
                  align="center"
                />
                <el-table-column label="Aksi" align="center">
                  <template #default="scope">
                    <div class="d-flex flex-wrap justify-content-center">
                      <base-button
                        @click="handleActive(scope.$index, scope.row)"
                        class="edit"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-check-circle" />Aktifkan
                      </base-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-skeleton animated :rows="1" :loading="isLoading">
                <template #template>
                  <el-skeleton-item variant="h1" />
                </template>
                <template #default>
                  <div>
                    <p class="card-category">
                      Urutan
                      {{ pagination.from ?? "0" + "-" + pagination.to }}
                      dari Total {{ pagination.total }} data
                    </p>
                  </div>
                  <base-pagination
                    class="pagination-no-border"
                    v-model="pagination.links"
                    @changePage="handleChangePage"
                    :currentPage="pagination.current_page"
                  >
                  </base-pagination>
                </template>
              </el-skeleton>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import EventTable from "../Events/helpers/eventTable";
import { ref } from "@vue/reactivity";
import moment from "moment";
import {
  baseComponentElement,
  useBaseTableHelperApi,
} from "@/helpers/baseTableHelper.js";
import { watch } from "vue";
import _ from "lodash";
import { Search } from "@element-plus/icons-vue";
import axios from "axios";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default {
  components: {
    ...baseComponentElement,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const toast = useToast();
    const baseUrl = "events";
    const tableColumns = EventTable;
    const {
      tableData,
      getActiveEvent,
      fetchListApi,
      pagedData,
      searchQuery,
      pagination,
      formatIndex,
    } = useBaseTableHelperApi();

    const searchedData = ref([]);

    const activeEvent = ref(null);

    const fetchActiveEvent = async () => {
      await getActiveEvent()
        .then((res) => {
          activeEvent.value = res;
        })
        .catch((err) => {
          activeEvent.value = err;
        });
    };
    fetchActiveEvent();

    const fetchEventList = _.debounce(async (url = null) => {
      let query = baseUrl + "?search=is_active:0";
      if (url) {
        query += url;
      }
      await fetchListApi(baseUrl, query)
        .then((res) => {
          tableData.value = res.data;
          delete res.data;
          for (let prop in res) {
            pagination[prop] = res[prop];
          }
        })
        .catch((err) => {
          toast.error(err.response.data.messages);
          router.replace("/dashboard");
        })
        .finally(() => {
          isLoading.value = false;
        });
    }, 500);
    fetchEventList();

    const formatDate = (row, column, cellValue) => {
      if (cellValue) {
        return moment(cellValue).locale("id").format("LL");
      }
    };
    const handleChangePage = (url) => {
      isLoading.value = true;
      fetchEventList(url);
    };
    const handleActive = async (index, row) => {
      isLoading.value = true;
      await axios
        .post("events/active/" + row.id, {
          _method: "PUT",
        })
        .then((res) => {
          toast.success(res.data.messages);
          fetchEventList();
        })
        .catch((err) => {
          toast.error(err.response.data.messages);
          isLoading.value = false;
        })
        .finally(() => {
          fetchActiveEvent();
        });
    };

    watch(searchQuery, () => {
      let searchUrl = ";name:" + searchQuery.value + "&searchJoin=and";
      fetchEventList(searchUrl);
    });
    return {
      isLoading,
      pagination,
      searchQuery,
      tableColumns,
      tableData,
      searchedData,
      activeEvent,
      pagedData,
      handleChangePage,
      formatIndex,
      formatDate,
      handleActive,
      Search,
    };
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
