<template>
  <div class="bg-theme">
    <main class="my-0 mx-auto scanner-container">
      <router-view />
    </main>
  </div>
</template>
<style>
.bg-theme {
  background: url("~@/assets/image/base.jpg") no-repeat center;
  background-size: cover;
}
.scanner-container {
  min-height: 100%;
  max-width: 480px !important;
}
.scanner-page {
  position: relative;
  max-width: 480px;
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  /* top: 48px; */
  /* padding: 0px 16px; */
  min-height: 100vh;
}
.scanner-footer {
  list-style-type: none;
  display: inline-block;
  border-width: 1px 0px 0px;
  border-style: initial;
  border-image: initial;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  width: 100%;
  max-width: 480px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  font-size: 1em;
  margin: 0px;
  /* padding: 10px; */
  /* border-top-style: solid; */
  /* border-top-color: rgb(222, 222, 222); */
  /* background-color: rgb(255, 255, 255); */
}
.footer-col {
  display: inline-block;
  width: 20%;
  font-size: 10px;
  cursor: pointer;
}
</style>
