import axios from "@/plugins/axios";
import { reactive, ref } from "vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export const baseComponentElement = {
  BasePagination,
  [ElSelect.name]: ElSelect,
  [ElOption.name]: ElOption,
  [ElTable.name]: ElTable,
  [ElInput.name]: ElInput,
  [ElTableColumn.name]: ElTableColumn,
};

export function useBaseTableHelperApi() {
  const pagination = reactive({
    current_page: 1,
    first_page_url: null,
    from: 1,
    last_page: 1,
    last_page_url: null,
    links: [],
    next_page_url: null,
    path: null,
    per_page: null,
    prev_page_url: null,
    to: null,
    total: null,
  });
  const tableData = ref([]);
  const pagedData = () => {
    return tableData.value;
  };
  const searchQuery = ref(null);

  const getActiveEvent = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("events/active")
        .then((res) => {
          resolve(res.data.data);
        })
        .catch(() => {
          reject(null);
        });
    });
  };

  const fetchListApi = (type, url = null) => {
    return new Promise((resolve, reject) => {
      const apiUrl = url ? url : type;
      axios
        .get(apiUrl)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const formatIndex = (index) => {
    return index + pagination.from;
  };

  return {
    tableData,
    getActiveEvent,
    pagedData,
    fetchListApi,
    pagination,
    formatIndex,
    searchQuery,
  };
}
