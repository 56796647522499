import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

export function useScannerApi() {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const guest = reactive({
    data: {},
    info: false,
    valid: "NOT_FOUND",
  });
  const scanType = reactive({
    value: true,
  });
  const userState = computed(() => store.getters["auth/getUser"]);
  const userRole = computed(() => store.getters["auth/getUserRole"]);
  const getPresenceType = computed(() => {
    // if (userRole.value == "super-admin") {
    //   return scanType.value ? "survey" : "deal";
    // } else if (userRole.value == "operator") {
    //   return "deal";
    // }
    // return "survey";
    if (userRole.value == "operator") {
      return "deal";
    }
    return scanType.value ? "survey" : "deal";
  });
  const goBack = () => {
    if (route.params.participant_id || userRole.value == "operator") {
      return "/scanners/vendor-list";
    }
    return "/";
  };
  const formatTime = (time) => {
    return moment(time).locale("id").format("dddd,DD MMMM YY HH:mm");
  };

  return {
    route,
    router,
    store,
    guest,
    scanType,
    userState,
    userRole,
    getPresenceType,
    goBack,
    formatTime,
  };
}
