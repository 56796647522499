<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0" />
          <!-- v-html="getActiveEvent('name')" -->
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            size="sm"
            type="success"
            :disabled="data.dashboard?.guests_count == 0"
            @click.prevent="exportGuest()"
            >Export</base-button
          >
          <base-button
            size="sm"
            type="neutral"
            :disabled="isLoading"
            @click.prevent="reFetchEvent()"
            >Refresh</base-button
          >
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Total Guests"
            type="gradient-red"
            icon="ni ni-active-40"
            :sub-title="data.dashboard?.guests_count ?? 0"
            :loading="isLoading"
          />
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Total Survey"
            type="gradient-orange"
            icon="ni ni-chart-pie-35"
            :sub-title="data.dashboard?.presences_survey_count ?? 0"
            :loading="isLoading"
          />
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Total Deal"
            type="gradient-green"
            icon="ni ni-money-coins"
            :sub-title="data.dashboard?.presences_deal_count ?? 0"
            :loading="isLoading"
          />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <dashboard-vendor-table
            :loading="isLoading"
            :data="data.guest"
            @changePage="handleChangePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseStatsCard from "../helpers/baseStatsCard";
import DashboardVendorTable from "./DashboardVendorTable.vue";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
import { useDashboardVendorApi } from "../composable/dashboardVendorApi";
import { useExportGuest } from "../composable/exportGuest";
import _ from "lodash";
import { useToast } from "vue-toastification";

export default {
  name: "dashboard-vendor",
  components: {
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
    DashboardVendorTable,
  },
  setup() {
    const { exportGuestBtn, exportGuest } = useExportGuest();
    const toast = useToast();
    const baseVendorStats = baseStatsCard.filter(
      (item) => item.title != "Total Vendors"
    );
    const { data, error, isLoading, getDashboardVendorData } =
      useDashboardVendorApi();

    if (error.value) {
      console.log("fetching-error", error);
    }

    const handleChangePage = (url) => {
      getDashboardVendorData(url);
    };

    const reFetchEvent = _.debounce(async () => {
      isLoading.value = true;
      await getDashboardVendorData();
      toast.success("Berhasil refresh data");
    }, 300);

    return {
      baseVendorStats,
      data,
      error,
      isLoading,
      reFetchEvent,
      handleChangePage,
      exportGuestBtn,
      exportGuest,
    };
  },
};
</script>
