const DashboardLayout = () => import("@/views/Layout/DashboardLayout");
const EventList = () => import("@/views/Events/List");
const EventCreate = () => import("@/views/Events/Create");
const EventEdit = () => import("@/views/Events/Edit");

export default {
  path: "/events",
  redirect: "/events",
  component: DashboardLayout,
  name: "Events",
  children: [
    {
      path: "",
      name: "Event List",
      components: { default: EventList },
      meta: { requiresAuth: true, requiresRole: "super-admin" },
    },
    {
      path: "create",
      name: "Event Create",
      components: { default: EventCreate },
      meta: { requiresAuth: true, requiresRole: "super-admin" },
    },
    {
      path: "edit/:slug",
      name: "Event Edit",
      components: { default: EventEdit },
      meta: { requiresAuth: true, requiresRole: "super-admin" },
    },
  ],
};
