import { ref, reactive } from "vue";
import axios from "@/plugins/axios";
import _ from "lodash";

export function useDashboardVendorApi() {
  const data = reactive({
    dashboard: {},
    guest: {},
  });
  const error = ref(null);
  const isLoading = ref(true);

  const getDashboardVendorData = _.debounce(async (url = null) => {
    await axios
      .all([
        axios.get("participants/dashboard-vendor"),
        axios.get(url ?? "guests/dashboard"),
      ])
      .then(
        axios.spread((res1, res2) => {
          data.dashboard = res1.data.data;
          data.guest = res2.data.data;
        })
      )
      .catch((err) => {
        error.value = err.response.data.message;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }, 300);
  getDashboardVendorData();

  return {
    data,
    error,
    isLoading,
    getDashboardVendorData,
  };
}
