<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6
            class="h2 text-white d-inline-block mb-0"
            v-html="getActiveEvent('name')"
          />
        </div>
        <div
          class="col-lg-6 col-5 text-right"
          v-if="userRole == 'super-admin' || userRole == 'organizer'"
        >
          <base-button
            size="sm"
            type="success"
            :disabled="exportGuestBtn"
            @click.prevent="exportGuest()"
            >Export</base-button
          >
          <base-button
            size="sm"
            type="neutral"
            :disabled="!(getActiveEvent('name') != null)"
            @click.prevent="reFetchEvent()"
            >Refresh</base-button
          >
        </div>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-xl-3 col-md-6"
          v-for="stat in baseStatsCard"
          :key="stat.title"
        >
          <stats-card
            :title="stat.title"
            :type="stat.type"
            :sub-title="getActiveEvent(stat.value)"
            :icon="stat.icon"
            :loading="isLoaded"
          />
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <transition name="slide-up" mode="out-in">
            <participant-details
              v-if="!selectAllParticipant"
              v-loading="isLoaded"
              element-loading-background="rgb(255,255,255) !important"
              :participants="getActiveEvent('participants')"
              @changeParticipant="handleChangeParticipant"
            />
            <participant-details-all
              ref="participantDetailAllRef"
              v-else
              v-loading="isLoaded"
              element-loading-background="rgb(255,255,255) !important"
              @changeParticipant="handleChangeParticipant"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseStatsCard from "../helpers/baseStatsCard";
import ParticipantDetails from "./ParticipantDetails.vue";
import ParticipantDetailsAll from "./ParticipantDetailsAll.vue";
import { computed, ref } from "@vue/reactivity";
import axios from "@/plugins/axios";
import { useToast } from "vue-toastification";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
import _ from "lodash";
import { useStore } from "vuex";
import { useExportGuest } from "../composable/exportGuest";

export default {
  name: "dashboard-all",
  components: {
    ParticipantDetails,
    ParticipantDetailsAll,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  setup() {
    const store = useStore();
    const userRole = computed(() => store.getters["auth/getUserRole"]);
    const toast = useToast();
    const isLoaded = ref(true);
    const activeEvent = ref({});
    const getActiveEvent = (field) => {
      if (activeEvent.value) {
        return activeEvent.value[field];
      }
      return null;
    };
    const selectAllParticipant = ref(false);
    const participantDetailAllRef = ref(null);
    const handleChangeParticipant = (val) => {
      if (val == "top") {
        selectAllParticipant.value = false;
      } else {
        selectAllParticipant.value = true;
      }
    };

    const getEventDashboard = _.debounce(async () => {
      await axios
        .get("events/dashboard")
        .then((res) => {
          activeEvent.value = res.data.data;
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          isLoaded.value = false;
          exportGuestBtn.value = false;
        });
    }, 300);
    getEventDashboard();

    const reFetchEvent = async () => {
      isLoaded.value = true;
      if (selectAllParticipant.value) {
        participantDetailAllRef.value.fetchParticipants();
      }
      await getEventDashboard();
      toast.success("Refresh Berhasil");
    };

    const { exportGuestBtn, exportGuest } = useExportGuest();
    // const exportGuestBtn = ref(true);
    // const exportGuest = _.debounce(async () => {
    //   exportGuestBtn.value = true;
    //   await axios
    //     .get("guests/export", {
    //       responseType: "blob",
    //     })
    //     .then((res) => {
    //       if (res.status == 204) {
    //         return toast.error("Data kosong");
    //       }
    //       const timestamps = moment().locale("id").format("Do-MMM-YY-hmm");
    //       const url = window.URL.createObjectURL(new Blob([res.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", `report-${timestamps}.xlsx`);
    //       link.click();
    //     })
    //     .finally(() => {
    //       exportGuestBtn.value = false;
    //     });
    // }, 300);

    return {
      isLoaded,
      activeEvent,
      getActiveEvent,
      reFetchEvent,
      baseStatsCard,
      selectAllParticipant,
      handleChangeParticipant,
      participantDetailAllRef,
      exportGuestBtn,
      exportGuest,
      userRole,
    };
  },
};
</script>
