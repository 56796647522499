<template>
  <div class="form-group">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div
      :class="[
        {
          'has-danger': !!errorMessage,
          'input-group': hasIcon,
          'input-group-alternative': alternative,
          focused: focused,
          'has-label': label || $slots.label,
        },
        inputGroupClasses,
      ]"
    >
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot>
        <el-skeleton animated :loading="isLoading">
          <template #default>
            <input
              class="form-control"
              :name="name"
              :id="name"
              :type="type"
              :placeholder="placeholder"
              :required="required"
              :disabled="isDisabled"
              :value="inputValue"
              @input="handleChange"
              @blur="focused = false"
              @focus="focused = true"
              :class="[{ 'border-danger': !!errorMessage }, { inputClasses }]"
            />
          </template>
          <template #template>
            <el-skeleton-item variant="button" style="width: 100%" />
          </template>
        </el-skeleton>
      </slot>
      <div
        v-if="addonRightIcon || $slots.addonRight"
        class="input-group-append"
      >
        <span class="input-group-text">
          <slot name="addonRight">
            <i :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script>
import { computed } from "vue";
import { useField } from "vee-validate";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
export default {
  name: "base-input",
  components: {
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  props: {
    addonRightIcon: String,
    addonLeftIcon: String,
    append: String,
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    inputGroupClasses: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const isLoading = computed(() => props.loading);
    const isDisabled = computed(() => props.disabled);
    const hasIcon = () => {
      const { addonRight, addonLeft } = slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        props.addonRightIcon !== undefined ||
        props.addonLeftIcon !== undefined ||
        props.group
      );
    };
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });
    return {
      focused: false,
      hasIcon,
      isDisabled,
      isLoading,
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style></style>
