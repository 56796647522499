<template>
  <qrcode-stream
    @init="onInit"
    @decode="onDecode"
    :camera="getCamera"
    :track="paintOutline"
  />
</template>
<script>
import { computed, ref } from "vue";
import { QrcodeStream } from "qrcode-reader-vue3";
import { useToast } from "vue-toastification";
export default {
  components: {
    QrcodeStream,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const camPermission = ref(false);
    const qrCamera = ref("auto");
    const getCamera = computed({
      get() {
        return qrCamera.value;
      },
      set(val) {
        qrCamera.value = val;
      },
    });
    const onInit = async (promise) => {
      await promise
        .then(() => {
          //   qrStream.value = false;
          camPermission.value = true;
          //   isValid.value = null;
        })
        .catch(() => {
          toast.error("Mohon izinkan akses kamera untuk menggunakan fitur ini");
          camPermission.value = false;
        });
    };
    function onDecode(decodeQr) {
      qrCamera.value = "off";
      emit("onDecode", decodeQr);
    }
    const paintOutline = (detectedCodes, ctx) => {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    };
    return {
      camPermission,
      onInit,
      paintOutline,
      qrCamera,
      getCamera,
      onDecode,
    };
  },
};
</script>
<style>
/* video.qrcode-stream-camera {
  -moz-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
} */
</style>
<style scoped>
.qrcode-stream-wrapper {
  height: 100vh !important;
}
</style>
