export default [
  {
    type: "index",
    label: "#",
    fixed: true,
  },
  {
    prop: "name",
    label: "Nama",
    minWidth: "300px",
    fixed: true,
  },
  {
    prop: "code",
    label: "Kode",
  },
  {
    prop: "date",
    label: "Tanggal",
  },
];
