<template>
  <main
    class="scanner-page shadow-lg bg-gradient-secondary d-flex flex-column overflow-hidden"
    style="max-height: 100vh"
  >
    <header class="fixed-top">
      <div class="navbar-header bg-dark py-2">
        <div class="container-fluid">
          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-2">
              <router-link :to="homePage()">
                <base-button type="white" size="sm">
                  <i class="fas fa-home" />
                </base-button>
              </router-link>
            </div>
            <div class="col">
              <el-input
                v-model="searchQuery"
                clearable
                placeholder="Cari Vendor"
                :suffix-icon="Search"
              />
            </div>
            <div class="col-2">
              <base-button type="white" size="sm" @click="fetchParticipant()">
                <i class="fas fa-sync-alt" />
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container mt-2 overflow-auto">
      <div class="row row-cols overflow-auto search-list">
        <div
          class="col mb-2"
          v-loading="data.loading"
          element-loading-background="rgb(255,255,255) !important"
        >
          <div
            class="card mb-2 participant-card"
            :class="randomBg()"
            v-for="item in data.participant.data"
            :key="item.id"
            @click="participantClick(item.id)"
          >
            <div class="card-body text-center">
              <h3 class="card-title text-white m-0" v-html="item.user.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-bottom">
      <div class="d-flex justify-content-center align-items-center py-2">
        <el-skeleton animated :rows="1" :loading="data.loading">
          <template #template>
            <el-skeleton-item variant="h1" />
          </template>
          <template #default>
            <base-pagination
              class="pagination-no-border mb-0"
              v-model="data.participant.links"
              :currentPage="data.participant.current_page"
              @changePage="handleChangePage"
            >
            </base-pagination>
          </template>
        </el-skeleton>
      </div>
    </div>
  </main>
</template>
<script>
import { reactive, ref, watch } from "vue";
import { Search } from "@element-plus/icons-vue";
import { ElInput, ElSkeleton, ElSkeletonItem } from "element-plus";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useBaseTableHelperApi } from "@/helpers/baseTableHelper";
import _ from "lodash";
import { useToast } from "vue-toastification";
export default {
  components: {
    [ElInput.name]: ElInput,
    [ElSkeleton.name]: ElSkeleton,
    [ElSkeletonItem.name]: ElSkeletonItem,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { getActiveEvent } = useBaseTableHelperApi();
    const data = reactive({
      loading: true,
      event: {},
      participant: {},
    });
    (async () => {
      await getActiveEvent()
        .then((res) => {
          data.event = res;
        })
        .catch((err) => {
          toast.error(err.response.data.messages);
        })
        .finally(() => {
          fetchParticipant();
        });
    })();
    const fetchParticipant = _.debounce(async (url = null) => {
      data.loading = true;
      await axios
        .get(url ?? "participants/events/" + data.event.id)
        .then((res) => {
          data.participant = res.data.data;
          toast.success("Berhasil mendapatkan data");
        })
        .catch((err) => {
          toast.error(err.response.data.messages);
          // router.push('/');
        })
        .finally(() => {
          data.loading = false;
        });
    }, 500);
    const handleChangePage = (url) => {
      fetchParticipant(url);
    };
    const searchQuery = ref(null);
    watch(searchQuery, (newVal) => {
      let url = "participants/events/" + data.event.id + "?search=" + newVal;
      data.loading = true;
      fetchParticipant(url);
    });
    const participantClick = (id) => {
      router.push({ name: "Scanners", params: { participant_id: id } });
    };

    const randomBg = () => {
      const items = [
        "gradient-default",
        "gradient-primary",
        "gradient-info",
        "gradient-success",
        "gradient-warning",
      ];
      return "bg-" + items[Math.floor(Math.random() * items.length)];
    };
    const homePage = () => {
      if (store.getters["auth/getUserRole"] == "operator") {
        return "/users/profile";
      }
      return "/";
    };
    return {
      fetchParticipant,
      Search,
      data,
      searchQuery,
      randomBg,
      participantClick,
      handleChangePage,
      homePage,
    };
  },
};
</script>
<style scoped>
.fixed-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.navbar-header {
  max-width: 480px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px auto;
  height: 60px;
}
.search-list {
  position: relative;
  top: 60px;
}
.navbar-bottom {
  list-style-type: none;
  display: inline-block;
  border-width: 1px 0px 0px;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  width: 100%;
  max-width: 480px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  padding: 10px;
  font-size: 1em;
  border-top-style: solid;
  border-top-color: rgb(222, 222, 222);
  margin: 0px;
  background-color: rgb(255, 255, 255);
}
.participant-card {
  transition: background 0.5s ease-in-out;
}
.participant-card:hover {
  background: #525f7f !important;
}
</style>
